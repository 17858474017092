.work_main {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  font-size: larger;
}
.work_main > .Component_header {
  margin-bottom: 25px;
}
.work {
  display: flex;
  color: var(--secondary-color);
  position: relative;
  column-gap: 20px;
}
.work_title_div {
  display: flex;
  width: 10vw;
  flex-direction: column;
  row-gap: 35px;
  height: 50vh;
}
.work_title_div > a {
  font-size: larger;
  padding-top: 5px;
  color: var(--secondary-color);
  padding: 10px;
}
.work_title_div > a:hover {
  color: var(--primary-red);
  background-color: var(--primary-body);
}

.work_title_div > a.active {
  color: var(--primary-red);
  border-left: solid 2px var(--primary-red);
  background-color: var(--primary-body);
}
.work_description {
  display: flex;
  flex: 1;
  float: left;
  flex-direction: column;
  align-items: flex-start;
}
.work_description_title {
  font-size: 28px;
  padding: 5px 5px 5px 0;
}
.work_description_data {
  letter-spacing: 0;
}
.work_description_list > ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.work_description_list > ul li {
  position: relative;
  padding-top: 30px;
  padding-left: 30px;
  margin-left: 0px;
}
.work_description_list > ul li:before {
  content: "\21E8";
  color: var(--primary-red);
  margin-right: 4px;
  position: absolute;
  left: 0px;
  font-weight: 700;
}
@media only screen and (max-width: 600px) {
  .work_main {
    width: 95%;
  }
  .work {
    flex-direction: column;
  }
  .work_title_div {
    flex-direction: row;
    height: 2.5rem;
    align-items: center;
    column-gap: 2px;
    position: relative;
    width: 100%;
    margin-bottom: 10px;
  }
  .work_title_div > a {
    font-size: 18px;
    color: var(--secondary-color);
    padding: 10px;
  }
  .work_description {
    font-size: large;
  }
}
