:root {
  --primary-red: #ce0010;
  --primary-red-hover: rgba(206, 0, 16, 0.1);
  --primary-color: white;
  --secondary-color: rgba(255, 255, 255);
  --tertiary-color: rgba(255, 255, 255);
  --primary-background: #000e22;
  --grid-background: #000711ce;
  --primary-body: #0007119f;
  --primary-font: "Roboto Condensed";
  --secondary-font: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono",
    monospace;
}

body {
  margin: 0;
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-background);
  letter-spacing: 1px;
  font-size: 18px;
}

a {
  color: var(--primary-red);
  text-decoration-line: none;
}
