.aboutMe {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
p {
  padding: 10px 10px 10px 0;
  font-size: larger;
  font-weight: 500;
  word-spacing: 5px;
  line-height: 26pt;
  width: 80%;
  color: var(--tertiary-color);
}

h1.description {
  font-size: clamp(70px, 7vw, 60px);
  color: var(--primary-red);
  font-weight: 900;
  padding: 10px;
  border-radius: 5px;
}

.printStatement {
  font-size: 50px;
  font-family: "Lucida Console", Courier, monospace;
  font-weight: 700;
  width: inherit;
}
.statement {
  padding: 15px;
}

.name {
  font-size: clamp(90px, 7.5vw, 70px);
  padding: 10px 0;
  font-weight: 1000;
  background-image: linear-gradient(
    -225deg,
    #fff 0%,
    var(--primary-color) 50%,
    var(--primary-red) 80%,
    #fff 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s reverse ease-out;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

h1.description span::before {
  content: "";
  animation: profile 5s infinite;
}
@keyframes profile {
  0% {
    content: "Web Developer";
  }
  100% {
    content: "Full Stack Enthusiast";
  }
}

@media only screen and (max-width: 600px) {
  .aboutMe {
    width: 95%;
  }
  p {
    width: 100%;
  }
  h1.name {
    font-size: clamp(65px, 6.5vw, 60px);
  }
  h1.description {
    font-size: clamp(35px, 6.5vw, 60px);
  }
  .printStatement {
    font-size: 30px;
  }
}
